import React, { useState } from 'react';
import Login from '../../components/Login'
import background from "../../assets/img/background.png" //Background
import back from "../../assets/img/backgroundmobile.png" //BackgroundM
import logo from '../../assets/img/logo.png'; //Logo
// import logo2 from '../../assets/img/logo3.png'; //Logo 2
import Footer from "../../components/Footer"
import "./style.css";

function Index(props) {
    const appName = 'Login'
    const [showLogin, setShowLogin] = useState(false)
    const deskripsi = "Selamat datang kembali di"
    const title1 = "Spenda Digital Learning"
    const slogan = '"Spenda Jaya Berkarakter Terpuji dan Berprestasi"'
    const copyright = "2024 SMP NEGERI 2 PENGASIH - Didukung oleh"

    return (
        <>

            <div className="col-12 hero-container">
                <div id="desktop" style={{ backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 100%, rgba(255, 255, 255, 0) 100%), url(${background})` }} className="hero-background col-12"></div>
                <div id="bg-mobile" style={{ backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 100%, rgba(255, 255, 255, 0) 100%), url(${back})` }} className="hero-background col-12"></div>
                <div className="col-12 hero" style={{ backgroundPosition: '0 90%', backgroundRepeat: 'no-repeat', backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.15) 80%, rgba(255, 255, 255, 0) 100%), url(${props.overlay})` }}>
                    <div className="" >
                        <div className="text-light" data-page={appName} style={{ textAlign: 'center' }}>
                            <div className='header' style={{ paddingBottom: '18vh' }}>
                                <img className='logo' src={logo} alt={appName} style={{ width: 70, height: 70, marginLeft: '20px', marginTop: '0px' }} />
                                {/* {<img className='logo' src={logo2} alt={appName} style={{ width: 230, height: 70, marginLeft: '20px', marginTop: '10px' }} /> */}
                                <h3 className='logo' style={{ marginLeft: '50px', marginTop: '20px', fontWeight: 600}}>SMP NEGERI 2 PENGASIH</h3>
                                <div style={{ flex: 1 }}>
                                </div>
                                <div className='login' onClick={() => setShowLogin(true)}>
                                    <p className='mt-1'>Login</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="" style={{ display: 'flex', paddingBottom: '2vh', alignItems: 'center', justifyContent: 'center' }}>
                                    <img className='logo2' src={logo} alt={appName} style={{ width: 70, height: 70 }} />
                                    {/* <img className='logo2' src={logo2} alt={appName} style={{ width: 170, height: 50 }} /> */}
                                </div>
                                <div className="" style={{ display: 'flex', paddingBottom: '2vh', alignItems: 'center', justifyContent: 'center' }}>
                                    <h2 style={{ fontSize: '150%' }}>
                                        {deskripsi}
                                    </h2>
                                </div>
                                <div className="title" style={{ display: 'flex', paddingBottom: '2vh', alignItems: 'center', justifyContent: 'center' }}>
                                    <h1 style={{ fontSize: '350%' }}>
                                        {title1}
                                    </h1>
                                </div>
                                <div className="sub-tittle" style={{ display: 'flex', paddingBottom: '2vh', alignItems: 'center', justifyContent: 'center', }}>
                                    <i style={{ fontSize: '150%' }}>
                                        {slogan}
                                    </i>
                                </div>
                                <div className="" style={{ display: 'flex', paddingBottom: '5vh', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className='login2' onClick={() => setShowLogin(true)}>
                                    <p className='mt-1'>Login</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {showLogin && <div onClick={e => setShowLogin(false)} style={{ position: 'fixed', backdropFilter: 'blur(4px)', top: 0, bottom: 0, right: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <div style={{ maxWidth: '600px', width: '100%' }} onClick={e => e.stopPropagation()}>
                            <Login />
                        </div>
                    </div>}
                </div>
                <Footer
                    copyright={copyright}
                />
            </div>
        </>
    );
}

export default Index;
